<template>
  <div class="txns-table">

    <ol v-if="totalItems" class="breadcrumb pl-2 pr-0 py-0 m-0 p-1 d-flex justify-content-between align-items-center">
      <li class="breadcrumb-item py-1">
        <span>แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ totalItems | amountFormat(0) }} รายการ</span>
        <span v-if="account" class="ml-2 game-selected">
          <span class="badge badge-pill badge-primary" @click="resetAccount">{{ username }} <i class="fas fa-times"></i></span>
        </span>
        <span v-if="game" class="ml-2 game-selected">
          <span class="badge badge-pill badge-primary" @click="resetGame">{{ gameName }} <i class="fas fa-times"></i></span>
        </span>
      </li>
      <li>
        <ul v-if="pages.length > 1" class="pagination justify-content-end mb-0">
          <li v-if="currentPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(currentPage - 1)"><i class="fas fa-angle-left"></i></a>
          </li>
          <li v-if="currentPage > 3" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(1)">1</a>
          </li>
          <li v-if="currentPage > 4 && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>

          <li
            v-for="(page, index) in pages"
            :key="index"
            class="page-item"
            :class="[{'active': page === currentPage}]"
            style="cursor: pointer; border-radius: 0;"
          ><a class="page-link rounded-0" @click="togglePage(page)">{{page}}</a></li>

          <li v-if="currentPage < (totalPage - 3) && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>
          <li v-if="currentPage < (totalPage - 2)" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(totalPage)">{{totalPage}}</a>
          </li>
          <li v-if="currentPage < totalPage" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(currentPage + 1)"><i class="fas fa-angle-right"></i></a>
          </li>
        </ul>
      </li>
    </ol>

    <table class="table table-bordered table-pp table-hover mb-0">
      <thead>
        <tr>
          <th width="50">ลำดับ</th>
          <th width="90">เลขที่</th>
          <th width="140" class="pb-1">ชื่อใช้งาน
            <b-form-checkbox
              v-model="showName"
              :value="true"
              :unchecked-value="false"
              class="mt-2 font-weight-normal"
            >
              แสดงชื่อ
            </b-form-checkbox>
          </th>
          <th width="180">เวลา</th>
          <th>เกม</th>
          <th width="100">เดิมพัน</th>
          <th width="100">คอมมิชชั่น</th>
          <th width="100">ชนะ</th>
          <th width="120">ได้-เสีย</th>
          <th width="100">สถานะ</th>
          <!-- <th width="140">หมายเหตุ</th> -->
           <th width="30"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="totalItems && items.length > 10">
          <th class="text-center" colspan="5">รวม</th>
          <th class="text-right" :class="[{'text-info': summary.betAmount > 0, 'text-secondary': summary.betAmount == 0}]">{{ summary.betAmount | amountFormat(2, '0.00') }}</th>
          <th class="text-right" :class="[{'text-info': summary.comAmount > 0, 'text-secondary': summary.comAmount == 0}]">{{ summary.comAmount | amountFormat(2, '0.00') }}</th>
          <th class="text-right" :class="[{'text-success': summary.payoutAmount > 0, 'text-secondary': summary.payoutAmount <= 0}]">{{summary.payoutAmount | amountFormat(2, '0.00')}}</th>
          <th class="text-right" :class="[{'text-success': summary.sumAmount > 0, 'text-secondary': summary.sumAmount == 0, 'text-danger': summary.sumAmount < 0}]">{{summary.sumAmount | amountFormat(2, '0.00')}}</th>
          <th colspan="2"></th>
          <!-- <th></th> -->
        </tr>
        <tr v-for="(item, index) in items" :key="item._id">
          <td class="text-center">{{offset+index+1}}</td>
          <td class="text-center px-0">{{item.txnNo}}</td>
          <td class="text-center px-0">
            <span class="text-primary game-link" @click="toggleAccount(item.account)">
              {{ item.account.username }}
              <small v-if="showName" class="text-secondary">({{item.account.firstName}})</small>
            </span>
          </td>
          <td class="text-center px-0">
            {{item.createdAt | dispDateTime("DD/MM/YYYY HH:mm:ss")}}
          </td>
          <td class="text-center px-0">
            <span class="text-primary game-link" @click="toggleGame(item.game)">{{ (item.game.name) }} ({{ item.game.creator.name }})</span>
          </td>
          <td class="text-right" :class="[{'text-info': item.dataAmount.betAmount > 0, 'text-secondary': item.dataAmount.betAmount == 0}]">{{item.dataAmount.betAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-right" :class="[{'text-info': item.dataAmount.comAmount > 0, 'text-secondary': item.dataAmount.comAmount == 0}]">{{item.dataAmount.comAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-right" :class="[{'text-success': item.dataAmount.payoutAmount > 0, 'text-secondary': item.dataAmount.payoutAmount <= 0}]">{{item.dataAmount.payoutAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-right" :class="[{'text-success': item.dataAmount.sumAmount > 0, 'text-secondary': item.dataAmount.sumAmount == 0, 'text-danger': item.dataAmount.sumAmount < 0}]">{{item.dataAmount.sumAmount | amountFormat(2, '0.00')}}</td>
          <td class="text-center"> <TxnStatus :status="item.status" /></td>
          <!-- <td class="text-center px-0">{{item.note}}</td> -->
          <td width="30" class="text-center"><i @click="viewInfo(item)" class="far fa-list-alt text-primary fa-xl txn-view-info"></i></td>
        </tr>
        <tr v-if="totalItems">
          <th class="text-center" colspan="5">รวม</th>
          <th class="text-right" :class="[{'text-info': summary.betAmount > 0, 'text-secondary': summary.betAmount == 0}]">{{ summary.betAmount | amountFormat(2, '0.00') }}</th>
          <th class="text-right" :class="[{'text-info': summary.comAmount > 0, 'text-secondary': summary.comAmount == 0}]">{{ summary.comAmount | amountFormat(2, '0.00') }}</th>
          <th class="text-right" :class="[{'text-success': summary.payoutAmount > 0, 'text-secondary': summary.payoutAmount <= 0}]">{{summary.payoutAmount | amountFormat(2, '0.00')}}</th>
          <th class="text-right" :class="[{'text-success': summary.sumAmount > 0, 'text-secondary': summary.sumAmount == 0, 'text-danger': summary.sumAmount < 0}]">{{summary.sumAmount | amountFormat(2, '0.00')}}</th>
          <th colspan="2"></th>
        </tr>
        <tr v-if="!totalItems">
          <td class="text-center" colspan="11">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>

    <ol v-if="totalItems" class="breadcrumb pl-2 pr-0 py-0 m-0 p-1 d-flex justify-content-between align-items-center">
      <li class="breadcrumb-item py-1">
        <span>แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ totalItems | amountFormat(0) }} รายการ</span>
      </li>
      <li>
        <ul v-if="pages.length > 1" class="pagination justify-content-end mb-0">
          <li v-if="currentPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(currentPage - 1)"><i class="fas fa-angle-left"></i></a>
          </li>
          <li v-if="currentPage > 3" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(1)">1</a>
          </li>
          <li v-if="currentPage > 4 && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>

          <li
            v-for="(page, index) in pages"
            :key="index"
            class="page-item"
            :class="[{'active': page === currentPage}]"
            style="cursor: pointer; border-radius: 0;"
          ><a class="page-link rounded-0" @click="togglePage(page)">{{page}}</a></li>

          <li v-if="currentPage < (totalPage - 3) && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>
          <li v-if="currentPage < (totalPage - 2)" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(totalPage)">{{totalPage}}</a>
          </li>
          <li v-if="currentPage < totalPage" class="page-item" style="cursor: pointer; border-radius: 0;">
            <a class="page-link rounded-0" @click="togglePage(currentPage + 1)"><i class="fas fa-angle-right"></i></a>
          </li>
        </ul>
      </li>
    </ol>

    <ReplayModal :isShow="isShowModal" :txnId="txnId" @close="isShowModal = false" />
  </div>
</template>
<script>
import TxnStatus from './TxnStatus'
import ReplayModal from './ReplayModal'

export default {
  name: 'TxnsTable',
  props: ['account', 'game', 'items', 'summary', 'totalItems', 'perPage', 'currentPage'],
  components: {
    TxnStatus,
    ReplayModal
  },
  data() {
    return {
      showName: false,
      txnId: null,
      isShowModal: false
    }
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalItems/this.perPage)
    },
    pages() {
      let prevPage = this.currentPage - 2
      let nextPage = this.currentPage + 2

      if(prevPage < 1) {
        nextPage = nextPage - prevPage + 1
      }else
      if(nextPage >= this.totalPage) {
        prevPage = prevPage - (nextPage - this.totalPage)
      }

      const startPage = prevPage < 1 ? 1 : prevPage
      const endPage = nextPage > this.totalPage ? this.totalPage : nextPage

      const pages = []
      for(let i=startPage; i<=endPage; i++) {
        pages.push(i)
      }

      return pages
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    startRow() {
      return this.offset + 1
    },
    endRow() {
      const max = this.startRow + this.perPage - 1
      return max < this.totalItems ? max : this.totalItems
    },
    gameName() {
      if(!this.game)
        return null

      return `${this.game.name} (${this.game.creator.name})`
    },
    username() {
      return this.account?.username ?? null
    }
  },
  methods: {
    togglePage(page) {
      this.$emit('togglePage', page)
    },
    toggleGame(game) {
      this.$emit('toggleGame', game)
    },
    resetGame() {
      this.$emit('toggleGame', null)
    },
    toggleAccount(account) {
      this.$emit('toggleAccount', account)
    },
    resetAccount() {
      this.$emit('toggleAccount', null)
    },
    viewInfo(item) {
      this.txnId = item._id
      this.isShowModal = true
    }
  }
}
</script>
<style lang="scss" scoped>
.game-link {
  cursor: pointer;
}

.game-link:hover {
  text-decoration: underline;
}

.game-selected {
  .badge {
    font-size: 95%;
    cursor: pointer;
  }

  .badge:hover {
    background-color: #DC3545;
  }
}

.txn-view-info {
  cursor: pointer;
}
</style>
