import { render, staticRenderFns } from "./Ambsportbook.vue?vue&type=template&id=3af86ef0&scoped=true&"
import script from "./Ambsportbook.vue?vue&type=script&lang=js&"
export * from "./Ambsportbook.vue?vue&type=script&lang=js&"
import style0 from "./Ambsportbook.vue?vue&type=style&index=0&id=3af86ef0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3af86ef0",
  null
  
)

export default component.exports