<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th width="50%">Player</th>
          <th width="50%">Banker</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in betResults" :key="index">
          <td>
            <div class="d-flex justify-content-center">
              <span v-for="card in item.player" class="game-card" :class="color(card.s)" v-if="card.r">
                {{ card.r }}<br />
                <i class="fas" :class="icon(card.s)"></i>
              </span>
            </div>
          </td>
          <td>
            <div class="d-flex justify-content-center">
              <span v-for="card in item.banker" class="game-card" :class="color(card.s)" v-if="card.r">
                {{ card.r }}<br />
                <i class="fas" :class="icon(card.s)"></i>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  name: 'Sagame',
  props: ['data'],
  computed: {
    betResults() {
      if(this.data.betResult?.banker) {
        return [this.data.betResult]
      }else{
        return Object.entries(this.data.betResult).map(([key, value]) => ({
          "round": key,
          "banker": value.banker,
          "player": value.player
        }))
      }
    }
  },
  methods: {
    icon(suit) {
      return {
        'S': 'fa-spade',
        'H': 'fa-heart',
        'C': 'fa-club',
        'D': 'fa-diamond'
      }[suit]
    },
    color(suit) {
      return {
        'S': 'text-dark',
        'H': 'text-danger',
        'C': 'text-dark',
        'D': 'text-danger'
      }[suit]
    }
  },
  mounted() {
    console.log(JSON.stringify(this.data, null, 2))
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
      }
    }
  }

  tbody {
    tr {
      td {
        text-align: center;

        .game-card {
          display: block;
          width: 60px;
          border: 1px solid #ccc;
          border-radius: 5px;
          margin: 2px;
          font-size: 30px;
        }
      }
    }
  }
}
</style>