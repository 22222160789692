import { render, staticRenderFns } from "./TxnsTable.vue?vue&type=template&id=692b035f&scoped=true&"
import script from "./TxnsTable.vue?vue&type=script&lang=js&"
export * from "./TxnsTable.vue?vue&type=script&lang=js&"
import style0 from "./TxnsTable.vue?vue&type=style&index=0&id=692b035f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "692b035f",
  null
  
)

export default component.exports