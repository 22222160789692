<template>
  <div class="p-2">
    <h4 class="text-center">รายละเอียด</h4>
    <table class="mb-3">
      <tr>
        <td width="90">เลขที่ตั๋ว: </td>
        <td>{{ betId }}</td>
      </tr>
      <tr>
        <td>ประเภท: </td>
        <td>{{ mainBetType }}</td>
      </tr>
    </table>
    
    <table class="table table-bordered mb-3 table-hover">
      <tbody>
        <tr v-for="(match, index) in matches" :key="match.matchId">
          <td width="20">{{ index+1 }}</td>
          <td>
            <div class="text-warning font-weight-bold">{{ leagueName(match) }}</div>
            <div>
              <span class="text-danger">{{ homeName(match) }}</span> -vs- <span class="text-primary">{{ awayName(match) }}</span>
            </div>
            <div>วันที่: {{ matchDateTime(match) }}</div>
            <div>ประเภท: <strong>{{ betType(match) }}</strong></div>
            <div>เดิมพัน: <strong>{{ betTeam(match) }}</strong></div>
            <div>ราคา: <span v-html="betPrice(match)"></span></div>
            <!-- <div>Result: <strong>{{ betResult(match) }}</strong></div>
            <div><strong v-html="halfTime(match)"></strong></div>
            <div><strong v-html="fullTime(match)"></strong></div> -->
          </td>
        </tr>
      </tbody>
    </table>

    <table class="mb-3">
      <tr>
        <td width="50%">จำนวนเงินเดิมพัน: </td>
        <td class="text-right">{{ stake | amountFormat(2, '0.00') }}</td>
      </tr>
      <tr>
        <td>Est./Payout: </td>
        <td class="text-right">{{ payout | amountFormat(2, '0.00') }}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import moment from '@/helpers/moment'

export default {
  name: 'AFB1188',
  props: ['data'],
  computed: {
    betId() {
      return this.data.betId
    },
    dateTime() {
      return moment(this.data.dateTime).format('DD/MM/YYYY HH:mm:ss')
    },
    matches() {
      return this.data?.matches ?? []
    },
    stake() {
      return this.data.stake
    },
    // summaryOdds() {
    //   return this.data.summaryOdds
    // },
    payout() {
      return this.data.payout
    },
    mainBetType() {
      return this.data.betType
    }
  },
  methods: {
    leagueName(match) {
      return match?.leagueName?.th ?? match?.leagueName?.en
    },
    homeName(match) {
      return match?.homeName?.th ?? match?.homeName?.en
    },
    awayName(match) {
      return match?.awayName?.th ?? match?.awayName?.en
    },
    matchDateTime(match) {
      return moment(match?.matchDateTime).format('DD/MM/YYYY HH:mm:ss')
    },
    betTeam(match) {
      return match?.betTeam
    },
    betType(match) {
      return {
        ah: 'HDP',
        ah1st: 'HDP@HT',
        ou: 'O/U',
        ou1st: 'O/U@HT',
      }?.[match?.betType] ?? match?.betType
    },
    betPrice(match) {
      return `${match?.value} @ ${match?.price}`
    },
    // betResult(match) {
    //   return match?.result
    // },
    // halfTime(match) {
    //   if(match?.halfTime){
    //     return `HT: ${match?.halfTime}`
    //   }
    // },
    // fullTime(match) {
    //   if(match?.fullTime){
    //     return `FT: ${match?.fullTime}`
    //   }
    // }
  },
  mounted() {
    console.log(JSON.stringify(this.data, null, 2))
  }
}
</script>
<style lang="scss" scoped>
table {
  font-size: 90%;
  width: 100%;

  td {
    vertical-align: middle;
  }
}
</style>